<template>
  <div class="search-bar">
    <div class="input-box">
      <van-icon name="search" />
      <van-field placeholder="搜索" disabled />
    </div>
    <div style="width: 40%; padding-left: 10px">
      <van-tabs
        v-model="active"
        color="#FF5200"
        line-width="10"
        title-active-color="#FF5200"
      >
        <van-tab title="首页"></van-tab>
        <van-tab title="全部" disabled></van-tab>
        <van-tab title="新品" disabled></van-tab>
      </van-tabs>
    </div>
    <div v-if="edit" class="edit-btn">
      <van-button
        size="mini"
        class="shadow-btn"
        @click="$router.push({ path: 'fitmentModel', query: { site: 0 } })"
        >插入</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "searchBar",
  props: {
    edit: {
      default: false,
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.search-bar {
  height: 32px;
  padding: 5px 3vw;
  background-color: #fff;
  display: flex;
  .input-box {
    width: 40%;
    position: relative;
    .van-icon {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 16px;
    }
    .van-field {
      height: 32px;
      border-radius: 16px;
      background-color: #f6f6f6;
      padding-left: 32px;
      /deep/ .van-field__body {
        height: 12px;
      }
    }
  }
  /deep/ .van-tabs__wrap {
    height: 32px;
  }
  /deep/ .van-tab--disabled {
    color: #646566;
  }
  .edit-btn {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .shadow-btn {
      box-shadow: 1px 1px 5px #e0e0e0;
      width: 56px;
    }
  }
}
</style>