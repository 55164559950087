<template>
  <div style="background-color: #f6f6f6">
    <van-nav-bar
      title="店铺装修"
      left-arrow
      @click-left="close"
      fixed
      placeholder
      right-text="预览"
      @click-right="preview"
    />
    <div style="padding: 2vw">
      <header-small
        v-if="fitment.storeType === '1'"
        :edit="true"
        :value="fitment.headerContent"
      ></header-small>
      <header-big
        v-else-if="fitment.storeType === '2'"
        :edit="true"
        :value="fitment.headerContent"
      ></header-big>

      <search-bar :edit="true"></search-bar>

      <div v-for="(item, index) in fitment.list" :key="index">
        <commodity-two
          v-if="item.modulesType == 1 && item.styleType == 1"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentGoods', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></commodity-two>

        <commodity-big-pic
          v-if="item.modulesType == 1 && item.styleType == 3"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentGoods', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></commodity-big-pic>

        <commodity-list
          v-if="item.modulesType == 1 && item.styleType == 2"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentGoods', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></commodity-list>

        <navigation-pic
          v-if="item.modulesType == 2 && item.styleType == 1"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentNav', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></navigation-pic>

        <navigation-slide
          v-if="item.modulesType == 2 && item.styleType == 2"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentNav', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></navigation-slide>

        <ad-big-pic
          v-if="item.modulesType == 3 && item.styleType == 1"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentAd', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></ad-big-pic>

        <ad-little-slide
          v-if="item.modulesType == 3 && item.styleType == 4"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentAd', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></ad-little-slide>

        <ad-two
          v-if="item.modulesType == 3 && item.styleType == 2"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentAd', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></ad-two>

        <ad-swiper
          v-if="item.modulesType == 3 && item.styleType == 3"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentAd', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></ad-swiper>

        <ad-big-slide
          v-if="item.modulesType == 3 && item.styleType == 5"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentAd', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></ad-big-slide>

        <text-scroll
          v-if="item.modulesType == 4 && item.styleType == 1"
          :primary="true"
          :value="item.childList"
          @delete="deleteItem(index)"
          @up="up(index)"
          @edit="edit('fitmentText', index, item.styleType, item.childList)"
          @insert="insert(index)"
        ></text-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import headerSmall from "@/components/fitment-box/header-small";
import headerBig from "@/components/fitment-box/header-big";
import searchBar from "@/components/fitment-box/search-bar";
import commodityTwo from "@/components/fitment-box/commodity-two";
import commodityBigPic from "@/components/fitment-box/commodity-big-pic";
import commodityList from "@/components/fitment-box/commodity-list";
import navigationPic from "@/components/fitment-box/navigation-pic";
import navigationSlide from "@/components/fitment-box/navigation-slide";
import adBigPic from "@/components/fitment-box/ad-big-pic";
import adLittleSlide from "@/components/fitment-box/ad-little-slide";
import adTwo from "@/components/fitment-box/ad-two";
import adBigSlide from "@/components/fitment-box/ad-big-slide";
import adSwiper from "@/components/fitment-box/ad-swiper";
import textScroll from "@/components/fitment-box/text-scroll";

import { mapState, mapGetters } from "vuex";

export default {
  name: "fitment",
  components: {
    headerSmall,
    headerBig,
    searchBar,
    commodityTwo,
    commodityBigPic,
    commodityList,
    navigationPic,
    navigationSlide,
    adBigPic,
    adTwo,
    adLittleSlide,
    adBigSlide,
    adSwiper,
    textScroll,
  },
  data() {
    return {};
  },
  created() {
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    // 获取预览信息
    if (this.fitment.first) {
      this.$store.dispatch("getStoreInfo").then((res) => {
        console.log(res);
      });
    }
    // 获取所有模块
    this.$store.dispatch("getConfigs").then((res) => {
      console.log(res);
      // 获取所有已解锁模块
      this.$store.dispatch("getUnlockedConfigs").then((res) => {
        console.log(res);
      });
    });
    // 获取所有已解锁类目
    this.$store.dispatch("getUnlockedCates").then((res) => {
      console.log(res);
    });
    // 获取所有已解锁商品
    this.$store.dispatch("getUnlockedGoods").then((res) => {
      console.log(res);
    });
    // 获取解锁橱窗数及解锁所需金币
    this.$store.dispatch("getShowcase").then((res) => {
      console.log(res);
    });
  },
  computed: {
    ...mapState(["fitment"]),
    ...mapGetters(["showCaseUsable"]),
  },
  methods: {
    // 预览
    preview() {
      if (!this.fitment.headerContent.storeName) {
        this.$toast("请编辑店铺名称");
        return;
      } else if (!this.fitment.headerContent.storeDescribe) {
        this.$toast("请编辑店铺简介");
        return;
      } else if (!this.fitment.headerContent.logoUrl) {
        this.$toast("请编辑店铺头像");
        return;
      } else if (!this.fitment.headerContent.signUrl) {
        this.$toast("请编辑店铺背景图");
        return;
      } else if (!this.fitment.list.length) {
        this.$toast("请编辑至少一个装修模块");
        return;
      }

      let flag = true;
      for (let index = 0; index < this.fitment.list.length; index++) {
        if (!this.fitment.list[index].childList.length) {
          this.$toast("请确保编辑完所有模块");
          flag = false;
          break;
        } else {
          for (
            let index2 = 0;
            index2 < this.fitment.list[index].childList.length;
            index2++
          ) {
            const item = this.fitment.list[index].childList[index2];
            if (item.status == 1) {
              // this.$toast("请先删除失效商品");
              this.deleteInvalid();
              flag = false;
              break;
            }
          }
        }
      }
      if (flag) {
        this.$router.push("fitmentPreview");
      }
    },
    // 删除失效商品
    deleteInvalid() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "存在失效商品，是否全部删除？",
        })
        .then(() => {
          console.log("confirm");
          let list = JSON.parse(JSON.stringify(this.fitment.list));
          list.map((x, y) => {
            if (x.modulesType == 1) {
              x.childList = x.childList.filter((n) => {
                return !n.status;
              });
              this.$store.commit("setListValue", {
                index: y,
                value: x.childList,
              });
            }
          });
        })
        .catch(() => {});
    },
    // 删除
    deleteItem(index) {
      this.$store.commit("listSplice", { index });
    },
    // 上移
    up(index) {
      if (index === 0) {
        this.$toast("该模块已在最上方");
      } else {
        this.$store.commit("listChange", index);
        this.$forceUpdate();
      }
    },
    // 编辑
    edit(path, index, type, value) {
      let flag = true;
      if (value.length) {
        value.map((x) => {
          console.log(x);
          if (x.status == 1) {
            // this.$toast("请先删除失效商品");
            this.deleteInvalid();
            flag = false;
          }
        });
        if (flag) {
          this.$store.commit("setEditList", JSON.parse(JSON.stringify(value)));
        }
      } else if (path.substr(7, 2) === "Ad") {
        if (type == 2) {
          this.$store.commit("setEditList", [
            {
              imageUrl: [],
              picUrl: "",
              filedId: null,
              name: "",
              advertType: null,
            },
            {
              imageUrl: [],
              picUrl: "",
              filedId: null,
              name: "",
              advertType: null,
            },
          ]);
        } else {
          this.$store.commit("setEditList", [
            {
              imageUrl: [],
              picUrl: "",
              filedId: null,
              name: "",
              advertType: null,
            },
          ]);
        }
      } else if (path.substr(7, 3) === "Nav") {
        this.$store.commit("setEditList", [
          {
            imageUrl: [],
            picUrl: "",
            filedId: null,
            name: "",
            navigationName: "",
            navigationType: "",
          },
          {
            imageUrl: [],
            picUrl: "",
            filedId: null,
            name: "",
            navigationName: "",
            navigationType: "",
          },
          {
            imageUrl: [],
            picUrl: "",
            filedId: null,
            name: "",
            navigationName: "",
            navigationType: "",
          },
          {
            imageUrl: [],
            picUrl: "",
            filedId: null,
            name: "",
            navigationName: "",
            navigationType: "",
          },
          {
            imageUrl: [],
            picUrl: "",
            filedId: null,
            name: "",
            navigationName: "",
            navigationType: "",
          },
        ]);
      } else if (path.substr(7, 5) === "Goods") {
        this.$store.commit("setEditList", []);
      } else {
        this.$store.commit("setEditList", [{ words: "" }]);
      }
      if (flag) {
        this.$router.push({
          path,
          query: { index, type, case: this.showCaseUsable, init: value.length },
        });
      }
    },
    // 插入
    insert(index) {
      this.$router.push({
        path: "fitmentModel",
        query: { site: index + 1 },
      });
    },
    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>