<template>
  <div class="header-small">
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
      <van-image
        width="100%"
        height="100%"
        :src="value.signUrl ? value.signUrl : bgi"
        fit="cover"
        style="margin: 0"
      />
    </div>
    <div class="header-left">
      <van-image
        width="13vw"
        height="13vw"
        fit="cover"
        :src="value.logoUrl ? value.logoUrl : src"
      />
      <div class="name">
        <div class="title">
          {{ value.storeName ? value.storeName : "店铺名称" }}
        </div>
        <div class="desc">
          {{ value.storeDescribe ? value.storeDescribe : "店铺简介" }}
        </div>
      </div>
    </div>
    <div class="header-right">
      <div v-if="edit" class="edit-btn" style="padding-right: 3vw">
        <van-button
          size="mini"
          @click="$router.push('fitmentModelHeader')"
          style="width: 56px"
          >编辑</van-button
        >
      </div>
      <div v-else style="padding-right: 16px">
        <div style="font-size: 20px; line-height: 20px">
          <van-icon name="star-o" />
        </div>
        <div style="font-size: 12px">收藏</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerSmall",
  components: {},
  props: {
    edit: {
      default: false,
    },
    // bgi: {
    //   default: require("@/assets/fitment/default-bgc.png"),
    // },
    // src: {
    //   default: require("@/assets/fitment/default.png"),
    // },
    value: {
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      bgi: require("@/assets/fitment/default-bgc.png"),
      src: require("@/assets/fitment/default.png"),
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.header-small {
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: relative;
  width: 96vw;
  height: 20vw;
  .header-left {
    display: flex;
    padding: 16px;
    z-index: 5;
    .van-image {
      margin-right: 10px;
      border-radius: 5px;
    }
    .name {
      .title {
        font-weight: 500;
        margin-bottom: 2px;
      }
      .desc {
        background-color: #fff;
        color: #333;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        padding: 0 10px;
      }
    }
  }
  .header-right {
    z-index: 5;
    text-align: center;
    width: 60px;
    position: relative;
    .edit-btn {
      padding-right: 3vw;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>